// дубль helpers.js для сохранения истории
import { CURRENCIES } from '../settings/constants'
import courses from '../settings/courses'

function getNestedObjValue(obj, stringPathToValue) {
  const arrayPathToValue = stringPathToValue.split('.')
  return arrayPathToValue.reduce((acc, key) => {
    if (!acc) return acc
    if (acc[key]) return acc[key]
    if (acc[key.toUpperCase()]) return acc[key.toUpperCase()]
    if (acc[key.toLowerCase()]) return acc[key.toLowerCase()]
    return null
  }, obj)
}

function getPaymentMethodType(learType, price) {
  const paymentMethodType = {
    full: 'full_prepayment',
    part: 'prepayment'
  }

  const currentCourse = getNestedObjValue(courses, learType)

  if (currentCourse.price < 1e5) {
    return paymentMethodType.part
  } else if (currentCourse && currentCourse.price) {
    return currentCourse.price === Number(price)
      ? paymentMethodType.full
      : paymentMethodType.part
  }
  return paymentMethodType.part
}

function getProductName(learType, withLocation) {
  const currentCourse = getNestedObjValue(courses, learType)
  if (currentCourse && currentCourse.productName) {
    return (
      currentCourse.productName +
      (withLocation ? ` ${learType.split('.')[1]}` : '')
    )
  }
  return 'Bootcamp'
}

function setTerminalKey($form) {
  const learType = $form.leartype.value
  const currentCourse = getNestedObjValue(courses, learType)
  if (currentCourse && currentCourse.terminalkey) {
    $form.terminalkey.value = currentCourse.terminalkey
  }
}

function getTaxation(learType) {
  const currentCourse = getNestedObjValue(courses, learType)
  if (currentCourse && currentCourse.taxation) {
    return currentCourse.taxation
  }

  return 'patent'
}

function createReceipt({
  email: { value: email },
  name: { value: name },
  amount: { value: price },
  leartype: { value: learType }
}) {
  return JSON.stringify({
    Email: email,
    Taxation: getTaxation(learType),
    Items: [
      {
        Name: getProductName(learType),
        Price: Number(price) * 100,
        Quantity: 1.0,
        Amount: Number(price) * 100,
        PaymentMethod: getPaymentMethodType(learType, price),
        PaymentObject: 'service',
        Tax: 'none'
      }
    ]
  })
}

function prettyPriceString(priceNumber, currency = CURRENCIES.RUB) {
  return `${Number(priceNumber).toLocaleString('ru-RU')} ${currency}`
}

function setPriceValue($form, price, originalPrice = null) {
  const $priceString = document.querySelector('#order .price')
  const $priceNumber = $form.amount
  if (originalPrice !== null) {
    $priceString.innerHTML = `<p style="text-decoration: line-through; font-weight: 200; font-size: 0.8em; margin-top: -4px; margin-bottom: -4px; color: gray;">${prettyPriceString(
      originalPrice,
      CURRENCIES.RUB
    )}</p> ${prettyPriceString(price, CURRENCIES.RUB)}`
  } else {
    $priceString.innerText = prettyPriceString(price, CURRENCIES.RUB)
  }
  $priceNumber.value = price
}

function getPriceFromCourse(coureseType = 'JS.msk') {
  const currentCourse = getNestedObjValue(courses, coureseType)
  return currentCourse && currentCourse.price
}

function setPrice($form, queryPrice, data) {
  if (queryPrice) {
    setPriceValue($form, queryPrice)
  } else {
    const priceFromStrapi = getPrice(data, $form.leartype.value)
    const isDiscounted = typeof priceFromStrapi.pricesWithDiscount === 'number'
    const finalPrice = isDiscounted
      ? priceFromStrapi.pricesWithDiscount
      : priceFromStrapi.productPriceFull
    setPriceValue(
      $form,
      finalPrice,
      isDiscounted ? priceFromStrapi.productPriceFull : null
    )
  }
}

function togglePromocodeBlock(isDiscounted) {
  const promocodeBlock = document.querySelector('.promocode')
  if (isDiscounted) {
    promocodeBlock.style.display = 'none'
  } else {
    promocodeBlock.style.display = 'block'
  }
}

function setPreOrderPrice($form, data) {
  let price
  switch ($form.leartype.value) {
    case 'js.msk.ru':
      price = 91500
      break
    case 'js.spb.ru':
      price = 79500
      break
    case 'js.kzn.ru':
      price = 63000
      break
    case 'js.online.ru':
      price = 70500
      break
    case 'ds.online.ru':
      price = 88500
      break
    case 'js.part_time_online.ru':
      price = 88500
      break
    default:
      price = getPriceFromCourse($form.leartype.value) || $form.amount.value
  }
  setPriceValue($form, price)
}

function setLearType($form, q = {}, data) {
  $form.leartype.value = q.leartype.toLowerCase()
  if (!q.s) {
    setPrice($form, q.s, data)
  }
}

function setEmail($form, email) {
  $form.email.value = email
}

function setName($form, name) {
  $form.name.value = name
}

function setLinkToDoc($form, q = {}) {
  const $docLinks = document.querySelectorAll('[data-docUrl]')
  let docLinkValue = ''
  let currentCourse = {}
  if (q.leartype) currentCourse = getNestedObjValue(courses, q.leartype)
  else currentCourse = getNestedObjValue(courses, $form.leartype.value)
  if (currentCourse && currentCourse.docUrl) docLinkValue = currentCourse.docUrl

  $docLinks.forEach(($docLink) => {
    $docLink.href = docLinkValue
  })
}

function setLogosClasses(learType) {
  const logos = document.querySelectorAll('.logo')
  logos.forEach((logoEl) => {
    logoEl.className = 'logo ' + detectLogoClass(learType)
  })
}

function detectLogoClass(learType) {
  if (!learType) return ''

  const arrayLeartype = learType.split('.')

  switch (arrayLeartype[1]) {
    case 'part_time_online':
      return 'online'

    case 'prepare':
      return arrayLeartype[0].toLowerCase()

    default:
      return arrayLeartype[1].toLowerCase()
  }
}

function setFormDataFromQuery($form, q = {}, data) {
  Object.keys(q).forEach((key) => {
    switch (key) {
      case 's':
        setPrice($form, q[key])
        break

      case 'leartype':
        setLearType($form, q, data)
        setLinkToDoc($form, q)
        setLogosClasses(q.leartype)
        setTerminalKey($form)
        break

      case 'email':
        setEmail($form, q[key])
        break

      case 'name':
        setName($form, q[key])
        break
      default:
        break
    }
  })
}

function getPrice(data, target) {
  const nameArr = target.split('.')
  return data.reduce((acc, cur) => {
    const isDiscountExpired =
      new Date(cur.discountDate).setHours(23, 59, 59, 999) < Date.now()
    if (cur.productName === `${nameArr[1]}_${nameArr[0]}`) {
      acc.productPriceFull =
        cur.productPriceFull && Number(cur.productPriceFull.replaceAll(' ', ''))
      acc.productPriceMonth =
        cur.productPriceMonth &&
        Number(cur.productPriceMonth.replaceAll(' ', ''))
      acc.pricesWithDiscount =
        !isDiscountExpired &&
        cur.pricesWithDiscount &&
        Number(cur.pricesWithDiscount.replaceAll(' ', ''))
    }
    return acc
  }, {})
}

function hasDiscount(learType, data) {
  const priceFromStrapi = getPrice(data, learType)
  return typeof priceFromStrapi.pricesWithDiscount === 'number'
}

function setInitialValue($form, data) {
  const selectedCity = document.querySelector('select').value
  const priceFromStrapi = getPrice(data, selectedCity)
  const discountPrice = priceFromStrapi?.pricesWithDiscount
  const priceNumber = discountPrice || priceFromStrapi.productPriceFull
  document.querySelector('.price').innerHTML = prettyPriceString(priceNumber)
  setPriceValue($form, priceNumber)
}

async function checkPromocode($form) {
  try {
    const response = await fetch('/promocode', {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code: $form.promocode.value })
    })
    const result = await response.json()
    const price = $form.amount.value
    const newPrice = (price * (100 - result.discount)) / 100
    setPriceValue($form, newPrice)
  } catch (e) {
    throw new Error(e)
  }
}

export {
  setFormDataFromQuery,
  setLinkToDoc,
  setLogosClasses,
  setPrice,
  setTerminalKey,
  createReceipt,
  setPreOrderPrice,
  setInitialValue,
  getProductName,
  checkPromocode,
  hasDiscount
}

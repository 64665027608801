// рабочий дубль client.js. client.js оставлен для сохранения истории
import {
  setFormDataFromQuery,
  setLinkToDoc,
  setLogosClasses,
  setPrice,
  setTerminalKey,
  createReceipt,
  setPreOrderPrice,
  setInitialValue,
  getProductName,
  checkPromocode,
  hasDiscount
} from './lib/utils'
import { getUtmFromCookie, setUtmToCookie } from './lib/utmCookie'
let data = []

function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

document.addEventListener('DOMContentLoaded', async function () {
  setUtmToCookie()
  await (async () => {
    const response = await fetch('/prices')
    data = await response.json()
  })()
  const $form = document.querySelector('form#order')
  setInitialValue($form, data)

  const q = Object.fromEntries(new URLSearchParams(window.location.search))
  const isPmLead = Boolean(q.pm)

  setFormDataFromQuery($form, q, data)
  if (q.c) {
    window.localStorage.setItem('clientToken', q.c)
  } else if (window.localStorage.getItem('clientToken')) {
    window.localStorage.removeItem('clientToken')
  }

  const selectField = document.querySelector('#coursesName')
  const promocodeBlock = document.querySelector('.promocode')
  const promocodeInputErrorHelper = document.querySelector('.error-helper')
  const promocodeInputAgree = document.querySelector('.promocode-info')
  const promocodeButton = document.querySelector('.outline-button')
  const gaUid = getCookie('_gid') ? getCookie('_gid').substring(6) : ''
  const yaUid = getCookie('_ym_uid') ? getCookie('_ym_uid') : ''
  promocodeButton.addEventListener('click', function (e) {
    e.preventDefault()
    e.stopPropagation()
  })

  $form.addEventListener('submit', function (e) {
    fetch('/sendLead', {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        eventName: 'Оплата курса через Pay',
        targetPage: 'pay.elbrusboot.camp',
        name: e.target.name.value.trim(),
        email: e.target.email.value,
        phone: e.target.phone.value,
        program: e.target.leartype.value,
        utm: getUtmFromCookie(),
        ga_uid: gaUid,
        ym_uid: yaUid,
        promocode: e.target.promocode.value
      })
    })
  })

  $form.addEventListener('change', async function (e) {
    if (
      selectField.value.includes('.prepare.') &&
      !hasDiscount(selectField.value, data)
    ) {
      promocodeBlock.style.display = 'block'
    } else {
      promocodeBlock.style.display = 'none'
    }
    if (e.target.type === 'select-one') {
      setLinkToDoc($form)
      setLogosClasses(e.target.value)
      isPmLead ? setPreOrderPrice($form) : setPrice($form, null, data)
    }
    if (e.target.name === 'promocode') {
      promocodeInputErrorHelper.style.display = 'none'
      promocodeInputAgree.style.display = 'block'
      if (e.target.value) {
        setPrice($form, null, data)
        try {
          await checkPromocode($form)
        } catch (e) {
          promocodeInputErrorHelper.style.display = 'block'
          promocodeInputAgree.style.display = 'none'
        }
      } else {
        setPrice($form, null, data)
      }
    }
    setTerminalKey($form)
    $form.receipt.value = createReceipt($form)
    $form.description.value = getProductName($form.leartype.value, true)
    $form.DATA.value = JSON.stringify({
      Name: $form.name.value,
      Email: $form.email.value
    })
  })
})

const nameInputField = document.querySelector('.input-name')
nameInputField.addEventListener('input', function () {
  if (nameInputField.validity.patternMismatch) {
    nameInputField.setCustomValidity(
      'Введите имя, фамилию и отчество (при наличии)'
    )
  } else {
    nameInputField.setCustomValidity('')
    nameInputField.value.trim()
  }
})

const phoneInputField = document.querySelector('.input-phone')
phoneInputField.addEventListener('beforeinput', function (e) {
  if (e.data && (isNaN(+e.data) || phoneInputField.value.length > 15)) {
    e.preventDefault()
    return
  }
})
phoneInputField.addEventListener('input', function (e) {
  if (!phoneInputField.value.startsWith('+')) {
    phoneInputField.value = '+' + phoneInputField.value
  }
  if (phoneInputField.validity.patternMismatch) {
    phoneInputField.setCustomValidity('Номер должен содержать от 11 до 15 цифр')
  } else {
    phoneInputField.setCustomValidity('')
    phoneInputField.value.trim()
  }
})

import Cookies from 'universal-cookie'

const UTM_COOKIE_NAME = 'utmCookie'
const UTM_COOKIE_EXPIRES = Date.now() + 1000 * 60 * 60 * 24

const getUtm = () => {
  const utms = {}
  const searchParams = new URLSearchParams(window.location.search)
  for (const key of searchParams.keys()) {
    if (key.toLowerCase().startsWith('utm_')) {
      utms[key] = new URLSearchParams(window.location.search).get(key)
    }
  }
  return utms
}

const cookies = new Cookies()

export const setUtmToCookie = () => {
  const UTM_GROUP = getUtm()

  const checkForEmpty = Object.values(UTM_GROUP).filter(Boolean)

  if (checkForEmpty.length !== 0) {
    cookies.set(UTM_COOKIE_NAME, UTM_GROUP, {
      path: '/',
      expires: new Date(UTM_COOKIE_EXPIRES)
    })
  }
}

export const getUtmFromCookie = () => cookies.get(UTM_COOKIE_NAME)
